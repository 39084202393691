import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserManagementApiService } from '@ltlc/api';
import { ConfirmationDialogService, ErrorHandlerService, RouterHelper, WebConfigService } from '@ltlc/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs/operators';
import { XpoLTLTrackingService } from './analytics';
import { APP_ROUTE_PATHS } from './app-core/constants/routes.const';
import { DynatraceService } from './app-core/services/dynatrace.service';
import { IdleService } from './app-core/services/idle.service';
import { PwaService } from './app-core/services/pwa.service';
import { MetaTitleService } from './shared/SEO/meta-title.service';
import { loginHint, navigateToOdicSignInPage } from './sign-in/sign-in.component';

@UntilDestroy() // In the unlikely event that this app is converted into a mfe.
@Component({
  selector: 'ltlc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private config: WebConfigService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService,
    private translateService: TranslateService,
    private dynatraceService: DynatraceService,
    private xpoLTLTrackingService: XpoLTLTrackingService,
    private idleService: IdleService,
    private pwaService: PwaService,
    private userManagementApiService: UserManagementApiService,
    private errorHandlerService: ErrorHandlerService,
    private metaTitleService: MetaTitleService
  ) {
    this.xpoLTLTrackingService.initializeTracking().pipe(take(1)).subscribe();
    this.dynatraceService.init();
    this.idleService.init();
    this.pwaService.init();
    this.metaTitleService.init();
  }

  // Handles messages sent up by freedompay via the iframe in billing/payment route.
  @HostListener('window:message', ['$event'])
  handleMessage(event: MessageEvent): void {
    // TODO: When freedom pay component is implemented in billing payment, we can remove this listener
    const freedomPayUrl = this.config.getSetting('freedomPayUrl');

    if (event.origin !== freedomPayUrl && event.origin.indexOf('10.10') < 0) {
      return;
    }

    const message = event.data;
    const data = message.data;

    switch (message.type) {
      case 1:
        console.error('ERROR FreedomPayListenerComponent', data);
        break;
      case 2:
        (<any>window).angularComponentReference?.zone?.run(() =>
          (<any>window).angularComponentReference.updateIframeHeight(data)
        );
        break;
      case 3:
        (<any>window).angularComponentReference?.zone?.run(() =>
          (<any>window).angularComponentReference.loadAngularFunction(data)
        );
        break;
    }
  }

  ngOnInit(): void {
    this.handleLegacyNavigation();
  }

  // Temp code to handle users that get redirected from the legacy site to the new site, and inform them to
  // update their bookmarks
  private handleLegacyNavigation(): void {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe((navigationEnd: NavigationEnd) => {
        // Check if there is the query parameter navigatedFromLegacy
        // If so: Prompt user to update their bookmarks
        if (this.activatedRoute.snapshot.queryParamMap.get('redirectedfromlegacy') === 'true') {
          // Clear the query params
          RouterHelper.addParams({ redirectedfromlegacy: null }, this.router);
          this.confirmationDialogService.open({
            actionColor: 'primary',
            title: this.translateService.instant('updateBookmarkTitle'),
            body: this.translateService.instant('updateBookmarkBody'),
            actionText: this.translateService.instant('actionDisplayGotIt'),
          });
        }

        if (navigationEnd.url.includes('postsignout')) {
          const userName: string = localStorage.getItem(loginHint);
          if (userName) {
            localStorage.removeItem(loginHint);
            this.userManagementApiService
              .getWebClient(userName)
              .pipe(take(1), this.errorHandlerService.snackbarOnError())
              .subscribe((clientId) => {
                navigateToOdicSignInPage(clientId, userName);
              });
            return;
          }

          // TODO: update ngx-auth to have a provider for where the application wants to end up on when signing out
          this.router.navigateByUrl(APP_ROUTE_PATHS.landing);
        }
      });
  }
}
